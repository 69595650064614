import styled, { ButtonGroup, SquareButton } from 'livemap-ui';
import React from 'react';

import { minusIcon, plusIcon } from '@shared/icons';
import { Translate, TranslateFunction } from 'react-localize-redux';

export interface ZoomButtonsDispatch {
  zoomIn(): void;
  zoomOut(): void;
}

export function ZoomButtons({ zoomIn, zoomOut }: ZoomButtonsDispatch) {
  return (
    <ButtonGroup>
      <Translate>
        {({ translate }: { translate: TranslateFunction }) => (
          <>
            <ZoomButton
              onClick={zoomIn}
              aria-label={translate('Map.buttons.zoomIn').toString()}
            >
              {plusIcon}
            </ZoomButton>
            <ZoomButton
              onClick={zoomOut}
              aria-label={translate('Map.buttons.zoomIn').toString()}
            >
              {minusIcon}
            </ZoomButton>
          </>
        )}
      </Translate>
    </ButtonGroup>
  );
}

const ZoomButton = styled(SquareButton)`
  svg {
    width: 14px;
    height: 14px;
  }
`;
