import React from 'react';

import { exitFullscreenIcon, fullscreenIcon } from '@shared/icons';
import { Translate, TranslateFunction } from 'react-localize-redux';
import styled, { SquareButton } from 'livemap-ui';

export interface FullscreenButtonProps {
  isFullscreen: boolean;
}

export interface FullscreenButtonDispatch {
  setFullscreen(isFullscreen: boolean): void;
}

type Props = FullscreenButtonDispatch & FullscreenButtonProps;

export function FullscreenButton({ isFullscreen, setFullscreen }: Props) {
  return (
    <Translate>
      {({ translate }: { translate: TranslateFunction }) => (
        <LargeIconButton
          onClick={() => setFullscreen(!isFullscreen)}
          aria-label={translate('Map.buttons.fullscreen')}
        >
          {isFullscreen ? exitFullscreenIcon : fullscreenIcon}
        </LargeIconButton>
      )}
    </Translate>
  );
}

export const LargeIconButton = styled(SquareButton)`
  > svg {
    width: 32px;
    height: 32px;
  }
`;
