import React from 'react';

import { RouteLayerStyle } from '@core/map/layers';
import { IconButton } from '@shared/form';
import { disableLayersIcon, layersIcon } from '@shared/icons';
import { Translate, TranslateFunction } from 'react-localize-redux';

export interface LayerSelectorDispatch {
  onClick(): void;
}

export interface LayerSelectorProps {
  layerStyle: RouteLayerStyle;
}

type Props = LayerSelectorDispatch & LayerSelectorProps;

export function LayerSelector({ layerStyle, onClick }: Props) {
  return (
    <Translate>
      {({ translate }: { translate: TranslateFunction }) => (
        <IconButton
          onClick={onClick}
          aria-label={translate('Map.buttons.layers').toString()}
        >
          {layerStyle === RouteLayerStyle.LINES
            ? disableLayersIcon
            : layersIcon}
        </IconButton>
      )}
    </Translate>
  );
}
