import React, { useEffect, useState } from 'react';

import styled, { ALL_CORNERS, FloatingPane, px } from 'livemap-ui';
import { AnyLayer } from 'mapbox-gl';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { ColorTheme, ComponentsConfig } from '@core/app-config';
import { AutosuggestItem, AutosuggestResult } from '@core/here';
import { useDebounce, useOnClickOutside } from '@core/hooks';
import { getRouteLayerId, getRouteLayers } from '@core/map/layers';
import { trimRouteFilterText } from '@core/route-filter';

import { searchIcon } from '@shared/icons';

import HamburgerButtonContainer from '../MenuButton/MenuButtonContainer';
import OmniboxSuggestionsContainer from './OmniboxSuggestions/OmniboxSuggestionsContainer';

export interface OmniboxDispatch {
  clearStop(): void;
  clearTrip(): void;
  clearResult(): void;
  setRouteFilter(layer: AnyLayer): void;
  setQuery(query: string): void;
  removeRouteFilter(layerId: string): void;
  fetchAutocomplete(query: string): void;
  selectItem(item: AutosuggestItem): void;
}

export interface OmniboxProps {
  query: string;
  theme: ColorTheme;
  isSearching: boolean;
  result: AutosuggestResult | null;
  components: ComponentsConfig;
}

type Props = OmniboxDispatch & OmniboxProps;

/** Component for location searches with type-ahead */
export function Omnibox(props: Props) {
  const items = props.result?.items?.filter(
    (item) => item.mapView || item.position
  );
  const debouncedQuery = useDebounce(props.query, 440);
  const isDebouncing = debouncedQuery !== props.query;
  const [containerElm, setContainerElm] = useState<Element | null>(null);

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      event.preventDefault();

      if (items?.length) {
        props.selectItem(items[0]);
        clearSuggestions();
      }
    }

    return true;
  };

  const renderSearchBarIcon = () => {
    if (props.isSearching || isDebouncing) {
      return (
        <Debouncing>
          <Dot />
          <Dot />
          <Dot />
        </Debouncing>
      );
    } else if (props.query) {
      return renderClearButton();
    }

    return searchIcon;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.query === '') {
      props.clearResult();
    }

    deselectRoute(trimRouteFilterText(props.query));

    props.setQuery(event.target.value);
  };

  const selectRoute = (routeId: string) => {
    const layers = getRouteLayers(routeId);

    if (layers.light) {
      props.setRouteFilter(layers.light);
    } else if (layers.dark) {
      props.setRouteFilter(layers.dark);
    }
  };

  const deselectRoute = (routeId: string) => {
    props.removeRouteFilter(getRouteLayerId(routeId, 'DAY'));
    props.removeRouteFilter(getRouteLayerId(routeId, 'NIGHT'));
  };

  const clearSuggestions = () => {
    setTimeout(() => {
      props.setQuery('');
      props.clearResult();
    }, 100);
  };

  useEffect(() => {
    selectRoute(trimRouteFilterText(debouncedQuery));

    props.fetchAutocomplete(debouncedQuery);
  }, [debouncedQuery]);

  const renderClearButton = () => {
    return (
      <ClearButton onClick={clearSuggestions}>
        <svg viewBox="0 0 24 24">
          <line x1="6" x2="18" y1="6" y2="18" />
          <line x1="6" x2="18" y1="18" y2="6" />
        </svg>
      </ClearButton>
    );
  };

  useOnClickOutside(containerElm, clearSuggestions);

  return (
    <Container ref={setContainerElm}>
      <Translate>
        {({ translate }: { translate: TranslateFunction }) => (
          <>
            <OmniPane corners={ALL_CORNERS}>
              {props.components.menu.enabled ? (
                <HamburgerButtonContainer
                  aria-label={translate('Search.menuButton').toString()}
                />
              ) : null}

              <SearchInput
                value={props.query}
                placeholder={translate('Search.search').toString()}
                onKeyDown={onKeyDown}
                onChange={handleChange}
                onFocus={() => {
                  props.clearStop();
                  props.clearTrip();
                }}
              />

              <SearchIcon>{renderSearchBarIcon()}</SearchIcon>
            </OmniPane>

            <OmniboxSuggestionsContainer
              isSearching={isDebouncing || props.isSearching}
            />
          </>
        )}
      </Translate>
    </Container>
  );
}

const ClearButton = styled.div`
  stroke: ${(p) => p.theme.contentColor};
  stroke-width: ${px(2)};
  opacity: ${(p) => p.theme.disabledOpacity};
`;

const Container = styled.div`
  width: 100%;
`;

const OmniPane = styled(FloatingPane)`
  z-index: 4;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${px(6)};

  button {
    margin: ${px(6, 0)};
    border: none;
    box-shadow: none;
  }
`;

const Debouncing = styled.figure`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: ${px(6)};

  > i:nth-child(2) {
    animation-delay: 100ms;
  }

  > i:nth-child(3) {
    animation-delay: 200ms;
  }
`;

const Dot = styled.i`
  @keyframes dot-bouncing {
    0% {
      transform: translateY(-2px);
    }
    90% {
      transform: translateY(2px);
    }
    100% {
      transform: translateY(-2px);
    }
  }

  transform: translateY(4px);
  display: block;
  width: ${px(4)};
  height: ${px(4)};
  background-color: ${(p) => p.theme.contentColor};
  opacity: ${(p) => p.theme.disabledOpacity};
  border-radius: 50%;
  animation: dot-bouncing infinite 640ms ease;
`;

const SearchInput = styled.input`
  background-color: transparent;
  align-self: stretch;
  margin-left: 6px;
  flex: 1 0 auto;
  transition: all 300ms ease;
  border: none;
  outline: none !important;
  padding: ${px(8, 0, 6, 6)};

  &::placeholder {
    transition: all 300ms;
    user-select: none;
  }

  &:focus::placeholder {
    padding-left: ${px(6)};
  }

  &:focus + figure svg {
    opacity: 1;
    fill: ${(p) => p.theme.detailColor};
  }
`;

const SearchIcon = styled.figure`
  margin: ${px(-1, 24, 0, 24)};
  width: ${px(16)};
  height: ${px(16)};
  position: absolute;
  right: 0;
  pointer-events: none !important;

  * {
    pointer-events: none !important;
  }

  > svg {
    fill: ${(p) => p.theme.contentColor};
    opacity: ${(p) => p.theme.dimOpacity};
    transition: all 250ms 50ms ease;
  }
`;
