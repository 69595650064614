import React from 'react';

import styled, { SquareButton } from 'livemap-ui';

import { GeolocationAction, TrazeMapConfig } from '@core/app-config';
import { Following } from '@core/map/position';

import { geolocateIcon } from '@shared/icons';
import { Translate, TranslateFunction } from 'react-localize-redux';

export interface GeolocatorProps {
  isVisible: boolean;
  action: GeolocationAction;
  enabled: boolean;
  following: Following;
  mapConfig: TrazeMapConfig;
}

export interface GeolocatorDispatch {
  geolocate(): void;
  setStartPosition(config: TrazeMapConfig): void;
}

type Props = GeolocatorProps & GeolocatorDispatch;

export function Geolocator({
  isVisible,
  enabled,
  action,
  geolocate,
  mapConfig,
  following,
  setStartPosition
}: Props) {
  if (!isVisible) {
    return null;
  }

  const followingUser = following === Following.USER;

  const onClick = (): void => {
    if (action === GeolocationAction.GEOLOCATE) {
      if (!followingUser) {
        geolocate();
      }
    } else {
      setStartPosition(mapConfig);
    }
  };

  return (
    <Translate>
      {({ translate }: { translate: TranslateFunction }) => (
        <GeolocateButton
          size={52}
          onClick={onClick}
          aria-label={translate('Map.buttons.geolocate')}
        >
          {geolocateIcon}
          {followingUser ? <FollowingDot /> : enabled ? <EnabledDot /> : null}
        </GeolocateButton>
      )}
    </Translate>
  );
}

const EnabledDot = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  top: calc(50% - 4px);
  left: calc(50% - 4px);
  border-radius: 50%;
  background-color: ${(p) => p.theme.contentColor};
`;

const FollowingDot = styled(EnabledDot)`
  background-color: ${(p) => p.theme.detailColor};
`;

const GeolocateButton = styled(SquareButton)`
  position: relative;
  margin: 12px;
  margin-left: auto;
  border-radius: 100%;
  border: none;

  svg {
    width: 24px;
    height: 24px;
  }
`;
