import React from 'react';

import styled, { SquareButton } from 'livemap-ui';

export interface DispatchProps {
  onClick: () => void;
}

type Props = DispatchProps;

export function MenuButton({ onClick, ...other }: Props) {
  return (
    <ButtonWrapper onClick={onClick} {...other}>
      {menuButtonIcon}
    </ButtonWrapper>
  );
}

const menuButtonIcon = (
  <svg viewBox="0 0 24 24">
    <line x1="4" x2="20" y1="6" y2="6" />
    <line x1="4" x2="20" y1="12" y2="12" />
    <line x1="4" x2="20" y1="18" y2="18" />
  </svg>
);

const ButtonWrapper = styled(SquareButton)`
  svg {
    width: 24px;
    height: 24px;
    stroke: ${(p) => p.theme.contentColor};
    opacity: ${(p) => p.theme.disabledOpacity};
    stroke-width: 2px;
    stroke-linecap: round;
    transition: all 300ms linear;
  }

  &:active svg {
    stroke: ${(p) => p.theme.detailColor};
    opacity: 1;
  }
`;
